<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <!-- 轮播图 -->
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/关于盛泰banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <div class="content">
      <div class="top_text">
        <el-card>
          <h2 class="H2">公司介绍</h2>
          <img class="img_1" src="@/assets/about/culture/企业文化图1.png" alt="">
          <img class="img_2" src="@/assets/about/culture/企业文化图2.png" alt="">
          <img class="img_3" src="@/assets/about/culture/修饰文字.png" alt="">
          <p v-for="item in text_top" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
        </el-card>
      </div>
      <div class="bottom_text">
        <el-card>
          <h2 class="H2">企业文化</h2>
          <img src="@/assets/about/culture/企业文化核心内容.png" alt="">
          <p v-for="item in text_bottom" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
        </el-card>
      </div>
    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/关于盛泰banner.png" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <div class="content">
      <h2>公司介绍</h2>
      <img class="img_1" src="@/assets/about/culture/企业文化图1.png" alt="">
      <img class="img_2" src="@/assets/about/culture/企业文化图2.png" alt="">
      <p v-for="item in text_top" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
     <h2>企业文化</h2>
     <img src="@/assets/about/culture/企业文化核心内容.png" alt=""/>
      <p v-for="item in text_bottom" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>

  </div>
</template>

<script>
import foot from '../components/foot.vue'
import headers from '../components/header.vue'
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { toRefs, reactive, ref, onMounted } from 'vue'
import { useRoute } from "vue-router";
export default {
  components: { foot, headers, move_foot, move_header },
  setup() {
    const Route = useRoute()
    const state = reactive({
      dataList: [],
      data: {},
      text_top: [
        { id: 1, text_: ' 盛泰光电2006年7月创立于深圳，2010年7月成立江西公司，2018年4月成立重庆公司，2019年成立印度公司。目前已形成重庆（总部+基地）中心、深圳（研发+销售）中心、江西+印度制造基地、台湾研发中心、新加坡研发中心，以及美国办事处的总体布局。' },
        { id: 2, text_: '公司自成立以来，一直保持快速增长，2020年实现产值36亿元，2021年产值超过40亿元，为社会提供就业岗位3,000余个，力争在未来3-4年内实现百亿产值。' },
        { id: 3, text_: '2020年公司规划占地面积260亩的盛泰光电科技企业孵化园投入建设。—期建设投资10亿元，建筑面积约83000㎡，其中产品技术研发中心8000㎡，联合实验室2000㎡，生产中心40000㎡，配套办公用房10000㎡，生活配套用房23000㎡。并在2022年正式投入了使用。' },
        { id: 4, text_: '公司建成现代化生产线85条、新建COB芯片封装线40余条、新购进超高精度AA生产线多条，摄像头产能达5亿个/年，现有高端摄像头模组生产车间全部升级为百级、千级无尘车间；同时，依托《中国制造2025》的国家战略，快速推进自动化、智能化生产，参照工业4.0标准向高技术、智能化、数字化制造企业快速迈进。' }
      ],
      text_bottom: [
        { id: 1, text_: '盛泰光电作为国内第一批摄像头模组制造企业，拥有实力雄厚的研发能力和制造能力。自成立以来，一直关注于手机摄像头模组的研发、制造、销售与服务，并向非手机包括笔记本、车载、医疗、AIOT等领域延伸，形成以手机摄像头模组领域为主，非手机摄像头多领域的新发展格局。目前摄像头模组出货量位居全球第五位；' },
        { id: 2, text_: '成立伊始，盛泰光电人一直秉持本分，拼搏，分享，创造的核心价值观，以立足光电行业，成为国际一流品牌客户的最佳选择作为企业使命，找一群志同道合的人，干一件可以说一辈子的事的企业精神，奉行以「奋斗者」为本，以「客户」为核心，以「品质」作基础，靠「技术」和「研发」永续发展的行为准则。实现做强，走远，做百年光电企业的企业愿景。' }
      ]
    })
    let methods = {}
    onMounted(() => {

    })
    return {
      ...toRefs(state), ...methods
    }
  }
}
</script>

<style scoped lang="less">
.box {

  width: 100%;

  .content {
    max-width: 1920px;
    margin: 50px auto;
    width: 70%;
    height: auto;
    p{font-size: 1.25rem;}
    .H2 {
      font-size: 1.7rem;
    }

    .H2::before {
      content: "";
      width: 30%;
      height: 2px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 40px;
      left: 6%;
      transform: translateX(-50%);
      // top:55px;
      min-width: 35px;
      max-width: 35px;
    }

    .top_text {
      box-sizing: border-box;
      position: relative;

      p {
        padding: 0 30px;
        Letter-spacing: 1px;
      }

      .img_1,
      .img_2 {
        width: 49%;
        border-radius: 15px;
      }

      .img_1 {
        margin-bottom: 0px;
      }

      .img_2 {
        margin: -40px 0 40px 20px;
      }

      .img_3 {
        width: 100%;
        margin: -57px 0 0 -35px;
      }
    }

    .bottom_text {
      margin-top: 30px;
      position: relative;

      img {
        width: 100%;
        margin: 20px 0;
      }

      p {
        padding: 0 30px;
        Letter-spacing: 1px;
      }
    }
  }
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .content {
      padding: 30px;
      box-sizing: border-box;
      .img_2{
        margin-top: 50px;
      }
      p{
        font-size: 60px;
      }
      img{
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
      }
            h2 {
        text-align: center;
        font-size: 80px;
      }



      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }
    }
  }
}
</style>